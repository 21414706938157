import { useRouter } from 'next/router'
import React, { createContext, useState, useContext, useEffect } from 'react'
import { fetchAssetTags } from 'data/queries'
import { findByEcosystem } from 'utils/findByEcosystem'
import { findByTag } from 'utils/findByTag'
import {
    BITCOIN_AND_OTHERS_KEY,
    BNB_ECOSYSTEM_KEY,
    COSMOS_ECOSYSTEM_KEY,
    ETHEREUM_ECOSYSTEM_KEY,
    LIQUID_RESTAKING_KEY,
    LIQUID_STAKING_KEY,
    NATIVE_RESTAKING_KEY,
    POLKADOT_ECOSYSTEM_KEY,
    POS_ASSET_CATEGORY,
    STABLECOIN_KEY,
    STABLECOINS_ASSET_CATEGORY,
} from 'utils/constants'

const AnnouncementBarContext = createContext()

export const AnnouncementBarProvider = ({ children }) => {
    const router = useRouter()
    const { slug, page } = router.query

    const [announcementBarState, setAnnouncementBarState] = useState({
        asset: null,
        tag: null,
        ecosystem: null,
    })

    useEffect(() => {
        if (
            router.asPath.startsWith('/asset/') &&
            !router.asPath.startsWith('/assets')
        ) {
            const fetchData = async () => {
                const response = await fetchAssetTags(slug)
                const tagKeys = response?.assets?.[0]?.tags?.map(
                    tag => tag.tagKey
                )
                const tagKeyEcosystem = findByEcosystem(tagKeys)

                const tagKeyTag = findByTag(tagKeys)

                setAnnouncementBarState({
                    asset: slug,
                    tag: tagKeyTag ? tagKeyTag : null,
                    ecosystem: tagKeyEcosystem ? tagKeyEcosystem : null,
                })
            }

            fetchData()
        } else if (router.asPath.startsWith('/assets')) {
            const isTag =
                page === LIQUID_STAKING_KEY ||
                page === STABLECOIN_KEY ||
                page === BITCOIN_AND_OTHERS_KEY ||
                page === LIQUID_RESTAKING_KEY ||
                page === NATIVE_RESTAKING_KEY ||
                page === POS_ASSET_CATEGORY.key ||
                page === STABLECOINS_ASSET_CATEGORY.key

            const isEcosystem =
                page === ETHEREUM_ECOSYSTEM_KEY ||
                page === COSMOS_ECOSYSTEM_KEY ||
                page === POLKADOT_ECOSYSTEM_KEY ||
                page === BNB_ECOSYSTEM_KEY

            setAnnouncementBarState({
                asset: null,
                tag: isTag ? page : null,
                ecosystem: isEcosystem ? page : null,
            })
        } else {
            setAnnouncementBarState({
                asset: null,
                tag: null,
                ecosystem: null,
            })
        }
    }, [router.asPath, page, slug])

    return (
        <AnnouncementBarContext.Provider value={{ announcementBarState }}>
            {children}
        </AnnouncementBarContext.Provider>
    )
}

export const useAnnouncementBar = () => {
    return useContext(AnnouncementBarContext)
}
