import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { Provider } from 'react-redux'
import { store } from 'state'
import { WalletState } from 'state/stakingPortfolio/walletState'
import { SWRConfig } from 'swr'
import { GraphQLClient } from 'graphql-request'
import { CustomAddressContextProvider } from 'contexts/customAddressContext'
import {
    ClarityTracking,
    GoogleTagManager,
    OptinMonsterTracker,
    RelicMonitoring,
} from 'utils/trackers'
import { BrellaScript } from 'utils/scripts'
import { useProgressBar } from 'hooks/useProgressBar'

import 'styles/global.scss'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Noop } from 'utils/noop'
import { REFRESH_INTERVAL_MS } from 'data/constants'
import { Ui } from 'components/ui/ui'
import { useGoogleTag } from 'components/ads/hooks'
import Intercom from '@intercom/messenger-js-sdk'

const client = new GraphQLClient(process.env.NEXT_PUBLIC_API_URL, {
    headers: {
        'X-API-Key': process.env.NEXT_PUBLIC_API_KEY,
    },
})

const isMaintenanceMode = process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 2,
            refetchOnWindowFocus: false,
        },
    },
})

export default function App({ Component, pageProps }) {
    // Temporary fix for signals bug, when trying to redirect from other pages to portfolio
    const a = WalletState.currentWallet.value
    useProgressBar()
    useGoogleTag()
    const router = useRouter()

    const ExtraProvider = Component.provider || Noop

    const isBrowser = typeof window !== 'undefined'

    useEffect(() => {
        // Check were client-side and initialize Intercom
        if (isBrowser && process.env.NEXT_PUBLIC_INTERCOM_ID) {
            Intercom({
                app_id: process.env.NEXT_PUBLIC_INTERCOM_ID,
            })
        }
    }, [isBrowser])

    // Render the component only if not in maintenance mode
    if (isMaintenanceMode || router.pathname === '/maintenance') {
        return (
            <Provider store={store}>
                <Component {...pageProps} />
            </Provider>
        )
    }

    return (
        <Provider store={store}>
            {/* NB: Declare outside of SeoTags.
            Otherwise scripts get minified into _app chunk,
            making them not recognizable by the tracking tools. */}
            {process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && (
                <TrackingScripts />
            )}
            {process.env.NEXT_PUBLIC_RELIC_MONITORING_ENABLED === 'true' && (
                <RelicMonitoring />
            )}
            <WidgetScripts />
            <SWRConfig
                value={{
                    refreshInterval: REFRESH_INTERVAL_MS,
                    fetcher: ([query, variables]) =>
                        client.request(query, variables),
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={true} />
                    <ExtraProvider>
                        <Ui>
                            <CustomAddressContextProvider>
                                <Component {...pageProps} />
                            </CustomAddressContextProvider>
                        </Ui>
                    </ExtraProvider>
                </QueryClientProvider>

                <div id='portal-root' />
            </SWRConfig>
        </Provider>
    )
}

export const TrackingScripts = () => {
    return (
        <>
            <ClarityTracking />
            <GoogleTagManager />
            <OptinMonsterTracker />
        </>
    )
}

export const WidgetScripts = () => {
    return <BrellaScript />
}
