import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { useUiState, useTheme } from 'state'
import { useMediaPredicate } from 'react-media-hook'
import { useIsMounted } from 'hooks'
import { BrandIcon } from 'components/brand'
import { Button, Link } from 'components/ui'
import { ProductsSubnav } from 'components/menu'

import styles from './header.module.scss'
import { GTMEvent, logEvent } from 'utils/GTM'
import { AnnouncementBar } from 'components/announcements/announcementBar'

// Depend on a portal rendered client-side
const HeaderButtons = dynamic(
    () => import('./headerButtons').then(mod => mod.HeaderButtons),
    {
        ssr: false,
    }
)

const WalletConnectDropdown = dynamic(
    () =>
        import('components/account/walletConnectDropdown').then(
            mod => mod.WalletConnectDropdown
        ),
    {
        ssr: false,
    }
)

const ChainSelector = dynamic(
    () =>
        import('components/headers/chainSelector').then(
            mod => mod.ChainSelector
        ),
    {
        ssr: false,
    }
)

function DesktopNav({ hide }) {
    return (
        <ul className={styles.desktopNav} data-hide={hide}>
            <li>
                <Link href='/assets'>Assets</Link>
            </li>
            <li>
                <Link href='/providers'>Providers</Link>
            </li>
            <li>
                <Link href='/summit'>Summit</Link>
            </li>
            <li>
                <div className={styles.subnavLink}>
                    <span>Products</span>

                    <ProductsSubnav className={styles.subnav} />
                </div>
            </li>
        </ul>
    )
}

export function Header({
    isPortfolioLandingPage = false,
    isPortfolioPage = false,
    isStakeAppPage = false,
    isAnnouncementBarVisible,
    setIsAnnouncementBarVisible,
}) {
    const theme = useTheme()
    const hasScrolled = useUiState('scrolled')
    const scrollDir = useUiState('scrollDir')
    const withPagesOnTop = useMediaPredicate('(min-width: 1280px)')

    const isMounted = useIsMounted()

    const hide = hasScrolled && scrollDir === 1

    return (
        <>
            <div className='fixed left-0 top-0 w-full z-[1000]'>
                {isAnnouncementBarVisible && (
                    <AnnouncementBar
                        setIsBarVisible={setIsAnnouncementBarVisible}
                    />
                )}
            </div>
            <div
                className={classNames(styles.header, {
                    '!pt-[70px] fixed': isAnnouncementBarVisible,
                })}
                data-theme={theme}
                data-hide={hide}
                data-scrolled={hasScrolled}
                data-mounted={isMounted}
            >
                <div className='container'>
                    <div className='flex flex-row items-center gap-x-4 top-1'>
                        <Link href='/' className={styles.brandIcon}>
                            <div className='flex items-center justify-center h-[50px]'>
                                <BrandIcon className={'pr-4 flex'} />
                                {isStakeAppPage ? (
                                    <span className='icon icon-stake-now-text !w-[124px] !h-[40px] -top-[1px] !hidden sm:!block'></span>
                                ) : (
                                    <div className='hidden font-semibold md:block'>
                                        Staking Rewards
                                    </div>
                                )}
                            </div>
                        </Link>
                    </div>

                    {withPagesOnTop && !isPortfolioPage && !isStakeAppPage && (
                        <DesktopNav hide={hide} />
                    )}

                    <div className={'flex flex-row gap-x-2 items-center'}>
                        <HeaderButtons
                            isStakeAppPage={isStakeAppPage}
                            isPortfolioLandingPage={isPortfolioLandingPage}
                            isPortfolioPage={isPortfolioPage}
                        />
                        {isPortfolioPage && <ChainSelector />}
                        {(isStakeAppPage ||
                            isPortfolioPage ||
                            isPortfolioLandingPage) && (
                            <WalletConnectDropdown />
                        )}
                        {!isStakeAppPage && !isPortfolioPage && (
                            <Link href='/stake-app'>
                                <Button
                                    size='md'
                                    iconClass='icon-sr'
                                    iconPosition='left'
                                    onClick={() => {
                                        logEvent(GTMEvent.StakeButtonTopNav)
                                    }}
                                >
                                    Stake Now
                                </Button>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
