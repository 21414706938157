import dynamic from 'next/dynamic'
import { useScreenSize } from 'hooks/useScreenSize'
import { Menu } from 'components/menu/menu'
import { LegalDisclaimers } from 'components/menu/legalDisclaimers'

import styles from './footer.module.scss'

const SmallMenu = dynamic(
    () => import('components/menu').then(mod => mod.SmallMenu),
    {
        ssr: false,
    }
)

export const Footer = () => {
    const { desktop } = useScreenSize()
    return (
        <div className={styles.footer}>
            {desktop ? <Menu isFooter /> : <SmallMenu isFooter />}
            <LegalDisclaimers />
        </div>
    )
}
