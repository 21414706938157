import { ASSET_CATEGORIES } from 'utils/constants'

export const FOOTER_VIEW_STAKING_REWARDS_KEY = 'stakingRewards'
export const FOOTER_VIEW_ASSETS_KEY = 'assets'
export const FOOTER_VIEW_PRODUCTS_KEY = 'products'
export const FOOTER_VIEW_ENTERPRISE_KEY = 'enterprise'
export const FOOTER_VIEWS = [
    { key: FOOTER_VIEW_STAKING_REWARDS_KEY, name: 'Staking Rewards' },
    { key: FOOTER_VIEW_ASSETS_KEY, name: 'Assets' },
    { key: FOOTER_VIEW_PRODUCTS_KEY, name: 'Products' },
    { key: FOOTER_VIEW_ENTERPRISE_KEY, name: 'Enterprise' },
]

export const DESCRIPTION = `
    We aim to make staking safe and easy. That is why we provide the platform, data, 
    and tools that institutional and retail investors need to navigate the world of staking. 
    We believe this is what it takes to bring crypto to the next 1 billion users.
`

export const STAKING_REWARDS_VIEW_LINKS = [
    {
        href: 'https://staking-rewards.breezy.hr/',
        blank: true,
        title: 'Jobs',
    },
]

export const PRODUCT_LINKS = [
    { title: 'srETH', href: '/sreth', blank: false, external: false },
    {
        title: 'Journal',
        href: '/journal',
        blank: false,
        external: false,
    },
    {
        title: 'Reward Calculator',
        href: '/calculator',
        blank: false,
        external: false,
    },
    {
        title: 'Staking Data API',
        href: '/staking-data-api',
        blank: false,
        external: false,
    },
    {
        title: 'Staking Terminal',
        href: '/terminal',
        blank: false,
        external: false,
    },
    {
        title: 'Staking App',
        href: '/stake-app',
        blank: false,
        external: false,
    },
    {
        title: 'VSP',
        href: '/vsp',
        blank: false,
        external: false,
    },
]

export const ENTERPRISE_LINKS = [
    {
        title: 'Sponsor the Summit',
        href: 'https://airtable.com/app6cnGzd6gGK8AMu/shrf5YINcCUvz3Xpb',
        blank: true,
        external: true,
    },
    {
        title: 'Get Your Asset Integrated',
        href: 'https://integration.stakingrewards.com/',
        blank: true,
        external: true,
    },
    {
        title: 'Advertise',
        href: 'https://stakingrewards.typeform.com/to/uEzXLMSV',
        blank: true,
        external: true,
    },
    {
        title: 'Staking Data API',
        href: 'https://stakingrewards.typeform.com/to/hXmkLb11',
        blank: true,
        external: true,
    },
    {
        title: 'Get Verified',
        href: 'https://stakingrewards.typeform.com/get-verified',
        blank: true,
        external: true,
    },
    {
        title: 'Claim Provider Profile',
        href: 'https://stakingrewards.typeform.com/claim-profile',
        blank: true,
        external: true,
    },
    {
        title: 'Institutional Investors',
        href: 'https://stakingrewards.typeform.com/institutional',
        blank: true,
        external: true,
    },
]

export const ASSETS_MENU_ITEMS = ASSET_CATEGORIES.map(category => ({
    ...category,
    href: `/assets/${category?.key}`,
    blank: false,
}))


