import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import styles from './search.module.scss'
import { ToggleButtonComponent } from 'components/ui/buttons/toggleButton'

export const Search = ({
    searchTerms = '',
    setSearchTerms = () => {},
    doFocus = false,
    onEscape = () => {},
    large = false,
    className = '',
}) => {
    const [readOnly, setReadOnly] = useState(doFocus)

    const inputRef = useRef(null)

    useEffect(() => {
        if (doFocus && inputRef?.current) {
            inputRef.current.focus()
            setReadOnly(false)
        }
    }, [doFocus]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={classNames(styles.searchBox, className, {
                [styles.large]: large,
            })}
        >
            <span className={`icon icon-search ${styles.icon}`} />
            <input
                className={styles.input}
                ref={inputRef}
                type='text'
                readOnly={readOnly}
                placeholder='Search'
                value={searchTerms}
                onChange={e => setSearchTerms(e.target.value)}
                onKeyDown={e => {
                    if (e.key === 'Escape') {
                        onEscape()
                    }
                }}
            />
            <div className={styles.clearBtn}>
                <ToggleButtonComponent
                    expanded
                    hidden={searchTerms === ''}
                    onClick={() => {
                        setSearchTerms('')
                    }}
                />
            </div>
        </div>
    )
}
