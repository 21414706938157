import { useMemo, useState } from 'react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { useScreenSize } from 'hooks/useScreenSize'
import { WithSearch } from './search'
import { AssetsView } from './assetsView'
import { NavigationMenu } from './navigationMenu'
import { SocialLinks } from './socialLinks'
import {
    DESCRIPTION,
    ENTERPRISE_LINKS,
    FOOTER_VIEWS,
    FOOTER_VIEW_ASSETS_KEY,
    FOOTER_VIEW_ENTERPRISE_KEY,
    FOOTER_VIEW_PRODUCTS_KEY,
    FOOTER_VIEW_STAKING_REWARDS_KEY,
    PRODUCT_LINKS,
    STAKING_REWARDS_VIEW_LINKS,
} from './constants'

const ViewWithLinks = dynamic(
    () => import('./viewWithLinks').then(module => module.ViewWithLinks),
    {
        ssr: false,
    }
)

const TrendingAssetsView = dynamic(
    () => import('./assetsView').then(module => module.TrendingAssetsView),
    {
        ssr: false,
    }
)

import styles from './menu.module.scss'

const SelectedViewContent = ({
    selectedViewKey = null,
    isDesktop = true,
    isFooter = true,
}) => {
    if (selectedViewKey === FOOTER_VIEW_STAKING_REWARDS_KEY) {
        return (
            <ViewWithLinks
                links={STAKING_REWARDS_VIEW_LINKS}
                description={DESCRIPTION}
                withSocialLinks={isDesktop}
                withAnnouncement={isDesktop}
            />
        )
    }

    if (selectedViewKey === FOOTER_VIEW_ASSETS_KEY) {
        return <AssetsView isDesktop={isDesktop} />
    }

    if (selectedViewKey === FOOTER_VIEW_PRODUCTS_KEY) {
        return (
            <ViewWithLinks
                links={[...PRODUCT_LINKS]}
                description={DESCRIPTION}
                withSocialLinks={false}
                withAnnouncement={false}
            />
        )
    }

    if (selectedViewKey === FOOTER_VIEW_ENTERPRISE_KEY) {
        return (
            <ViewWithLinks
                links={ENTERPRISE_LINKS}
                description={DESCRIPTION}
                withSocialLinks={false}
                withAnnouncement={false}
            />
        )
    }

    return isDesktop ? <TrendingAssetsView isFooter={isFooter} /> : null
}

export const Menu = ({ className = '', isFooter = false }) => {
    const [selectedViewKey, setSelectedViewKey] = useState(null)
    const { desktop } = useScreenSize()

    // Memoize for memoizing NavigationMenu
    const onSelect = useMemo(
        () => newView => {
            setSelectedViewKey(newView?.key)
        },
        []
    )

    return (
        <div
            className={classNames(
                styles.menu,
                { [styles.offset]: !isFooter },
                className
            )}
        >
            <div className={classNames('container', styles.container)}>
                <div
                    className={classNames(styles.contentWrap, {
                        [styles.withMenu]:
                            selectedViewKey === FOOTER_VIEW_ASSETS_KEY,
                    })}
                    onMouseLeave={() => {
                        setSelectedViewKey(null)
                    }}
                >
                    <div className={styles.navigationWrap}>
                        <NavigationMenu
                            items={FOOTER_VIEWS}
                            selected={selectedViewKey}
                            onSelect={onSelect}
                            selectOnHover={desktop}
                        />
                    </div>
                    <div className={styles.content}>
                        <SelectedViewContent
                            selectedViewKey={selectedViewKey}
                            isDesktop={desktop}
                            isFooter={isFooter}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const SmallMenu = ({ className = '', isFooter = true }) => {
    const [selectedView, setSelectedView] = useState(null)
    return (
        <WithSearch focusOnMount={!isFooter}>
            <div className={classNames(styles.menuOnMobile, className)}>
                <div className={classNames('container', styles.container)}>
                    <div className={styles.mobileNavigationMenu}>
                        {FOOTER_VIEWS.map((view, idx) => (
                            <div
                                key={`footer-page-${view.key}-${idx}`}
                                className={classNames(styles.mainLink, {
                                    [styles.selected]:
                                        view.key === selectedView?.key,
                                })}
                            >
                                <div
                                    className={classNames(styles.navItem)}
                                    onClick={() => {
                                        setSelectedView(
                                            view.key === selectedView?.key
                                                ? null
                                                : view
                                        )
                                    }}
                                >
                                    {`${view.name}`}
                                </div>
                                <div className={styles.content}>
                                    <SelectedViewContent
                                        selectedViewKey={selectedView?.key}
                                        isDesktop={false}
                                        isFooter={isFooter}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.social}>
                        <SocialLinks />
                    </div>
                </div>
            </div>
        </WithSearch>
    )
}
