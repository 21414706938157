import { Link } from 'components/ui/links/link'

import styles from './legalDisclaimers.module.scss'

export const LegalDisclaimers = () => {
    return (
        <div className='container'>
            <div className={styles.legalDisclaimers}>
                <div className={styles.disclaimers}>
                    <Link className={styles.text} href={'/disclaimer'}>
                        Disclaimer
                    </Link>
                    <span className={styles.separator} />
                    <Link className={styles.text} href={'/privacy-policy'}>
                        Privacy Policy
                    </Link>
                    <span className={styles.separator} />
                    <Link
                        className={styles.text}
                        href={'/terms-and-conditions'}
                    >
                        Terms of Service
                    </Link>
                </div>
                <p
                    className={styles.text}
                >{`${new Date().getFullYear()}©Staking Rewards.`}</p>
            </div>
        </div>
    )
}
