import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import { useClickOutside } from 'hooks/useClickOutside'
import { useLockBodyScroll } from 'hooks/useLockBodyScroll'
import { MAX_TOOLTIP_TEXT_LENGTH, truncateString } from 'utils/formatter'
import { HorizontalSeparator } from './horizontalSeparator'
import { Portal } from './portal'

import styles from './tooltip.module.scss'

const CloseButton = ({ onClick = () => {} }) => {
    return (
        <button
            className={styles.closeBtn}
            onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onClick()
            }}
        >
            <span className={styles.bar1} />
            <span className={styles.bar2} />
        </button>
    )
}

export const TooltipOverlay = ({
    title = '',
    onClose = () => {},
    children,
}) => {
    const contentRef = useRef(null)
    useClickOutside(contentRef, onClose)

    useLockBodyScroll(true)

    return (
        <Portal>
            <div className={classNames('vertical-scroll', styles.overlay)}>
                <div className={styles.contentWrap} ref={contentRef}>
                    <div className={styles.headerWrap}>
                        <CloseButton onClick={onClose} />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.contentText}>
                            <div className={styles.padded}>{children}</div>
                        </div>
                        <div className={styles.gradient} />
                    </div>
                </div>
            </div>
        </Portal>
    )
}

export const Tooltip = ({
    className = '',
    textClassName = '',
    text = '',
    children,
    withExpansion = false,
    withReadMore = false,
    onReadMore = () => {},
    updatedTimeStatus = null,
    // Control width of the target
    hasInputTarget = false,
}) => {
    const showTooltip = Boolean(text && text !== '')

    return (
        <div
            className={classNames({
                [styles.tooltip]: showTooltip,
                [styles.inputWrap]: hasInputTarget,
                [className]: showTooltip,
                'cursor-pointer': withReadMore,
            })}
            onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                if (withReadMore) {
                    onReadMore()
                }
            }}
        >
            {showTooltip && (
                <div className={classNames(styles.text, textClassName)}>
                    {updatedTimeStatus && (
                        <div className='flex flex-col mb-2 gap-y-2'>
                            <div className='flex flex-row items-center justify-between'>
                                <p className='text-[10px]'>
                                    {`Last Updated: ${updatedTimeStatus}`}
                                </p>
                                {withExpansion && (
                                    <span className='icon icon-expand !bg-contrast-3 !w-[12px] !h-[12px]' />
                                )}
                            </div>
                            <HorizontalSeparator className='!mb-0' />
                        </div>
                    )}
                    {text}{' '}
                    {withReadMore && (
                        <span className={styles.more}>Read more</span>
                    )}
                </div>
            )}
            {children}
        </div>
    )
}

export const TooltipContrast = ({
    className = '',
    textClassName = '',
    text = '',
    children,
    withExpansion = false,
    withReadMore = false,
    onReadMore = () => {},
    updatedTimeStatus = null,
    // Control width of the target
    hasInputTarget = false,
}) => {
    const showTooltip = Boolean(text && text !== '')

    return (
        <div
            className={classNames({
                [styles.tooltipContrast]: showTooltip,
                [styles.inputWrap]: hasInputTarget,
                [className]: showTooltip,
                'cursor-pointer': withReadMore,
            })}
            onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                if (withReadMore) {
                    onReadMore()
                }
            }}
        >
            {showTooltip && (
                <div className={classNames(styles.text, textClassName)}>
                    {updatedTimeStatus && (
                        <div className='flex flex-col mb-2 gap-y-2'>
                            <div className='flex flex-row items-center justify-between'>
                                <p className='text-[10px]'>
                                    {`Last Updated: ${updatedTimeStatus}`}
                                </p>
                                {withExpansion && (
                                    <span className='icon icon-expand !bg-contrast-3 !w-[12px] !h-[12px]' />
                                )}
                            </div>
                            <HorizontalSeparator className='!mb-0' />
                        </div>
                    )}
                    {text}{' '}
                    {withReadMore && (
                        <span className={styles.more}>Read more</span>
                    )}
                </div>
            )}
            {children}
        </div>
    )
}

// NB: do not pass truthy overlay for custom expansion logic
export const TooltipOnHover = ({
    children,
    overlay = null,
    tootltipContrast = false,
    ...props
}) => {
    const hoverableClass = styles.hoverable

    const [showOverlay, setShowOverlay] = useState(false)

    return tootltipContrast ? (
        <TooltipContrast
            {...props}
            className={classNames(hoverableClass, props?.className ?? '')}
            withReadMore={props?.withReadMore}
            onReadMore={() => {
                if (overlay) {
                    setShowOverlay(true)
                } else {
                    props?.onReadMore?.()
                }
            }}
        >
            {children}
            {overlay && showOverlay && (
                <TooltipOverlay
                    title={overlay?.title ?? ''}
                    onClose={() => {
                        setShowOverlay(false)
                        overlay?.onClose?.()
                    }}
                >
                    <div className={overlay.className}>
                        {overlay?.allText ?? ''}
                        {overlay?.footer ?? ''}
                    </div>
                </TooltipOverlay>
            )}
        </TooltipContrast>
    ) : (
        <Tooltip
            {...props}
            className={classNames(hoverableClass, props?.className ?? '')}
            withReadMore={props?.withReadMore}
            onReadMore={() => {
                if (overlay) {
                    setShowOverlay(true)
                } else {
                    props?.onReadMore?.()
                }
            }}
        >
            {children}
            {overlay && showOverlay && (
                <TooltipOverlay
                    title={overlay?.title ?? ''}
                    onClose={() => {
                        setShowOverlay(false)
                        overlay?.onClose?.()
                    }}
                >
                    <div className={overlay.className}>
                        {overlay?.allText ?? ''}
                        {overlay?.footer ?? ''}
                    </div>
                </TooltipOverlay>
            )}
        </Tooltip>
    )
}

export const withTooltip = Component => {
    return function ComponentWithTooltipWrapper(props) {
        const tooltipObject = props?.tooltipObject ?? {}
        const tooltipClassName = props?.tooltipClassName ?? ''
        const overlay =
            (tooltipObject?.text?.length ?? 0) > MAX_TOOLTIP_TEXT_LENGTH
                ? {
                      title: tooltipObject?.title ?? '',
                      allText: tooltipObject?.text ?? '',
                      footer: tooltipObject?.footer ?? '',
                  }
                : null
        return (
            <TooltipOnHover
                text={truncateString(
                    tooltipObject?.text ?? '',
                    MAX_TOOLTIP_TEXT_LENGTH
                )}
                textClassName={tooltipClassName}
                overlay={overlay}
                updatedTimeStatus={props?.updatedTimeStatus}
            >
                <Component {...props} />
            </TooltipOnHover>
        )
    }
}
