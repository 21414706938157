import classNames from 'classnames'
import { useMediaPredicate } from 'react-media-hook'
import { useTheme, useUiState } from 'state'
import { useLockBodyScroll } from 'hooks'
import { Menu } from 'components/menu/menu'
import { MobileMenu } from 'components/menu/mobileMenu'

import styles from './overlay.module.scss'

export function Overlay() {
    const theme = useTheme()
    const hamburgerActive = useUiState('hamburgerActive')
    const overlayScreen = useUiState('overlayScreen')
    const withDesktopMenu = useMediaPredicate('(min-width: 1280px)')

    useLockBodyScroll(hamburgerActive)
    return (
        <div
            className={classNames(styles.overlay, {
                [styles.mobileOverlay]: !withDesktopMenu,
                [styles.closeAnimation]: !hamburgerActive,
            })}
            data-theme={theme}
        >
            <div className={styles.wrap}>
                {hamburgerActive &&
                    overlayScreen === 'menu' &&
                    (withDesktopMenu ? (
                        <Menu className={styles.menu} isFooter={false} />
                    ) : (
                        <MobileMenu />
                    ))}
            </div>
        </div>
    )
}
