import { useEffect, useState } from 'react'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'
import { gql } from 'graphql-request'
import {
    getDaysBetweenDates,
    getMetricDefaultValueByKey,
    getMetricPercentage,
    getMetricValueByKey,
    isMetricWithExpansion,
} from 'utils/actions'
import {
    TYPE_ASSET,
    ASSET_CATEGORIES,
    PAGE_SIZE_20,
    TYPE_PROVIDER,
    TYPE_VALIDATOR,
    PROVIDER_CATEGORIES,
    SOLO_STAKING_KEY,
    SMART_CONTRACT_KEY,
    POS_PROVIDER_KEY,
    ALL_KEY,
    LIQUID_STAKING_PROVIDER_KEY,
    DEFAULT_ASSET_TIMEFRAME,
    AVS_KEY,
    BITCOIN_AND_OTHERS_KEY,
    DEFAULT_REWARD_OPTION_TIMEFRAME,
} from 'utils/constants'
import { toCamelCase, isInvalidNumber, formatDateToISO } from 'utils/formatter'

export const REFRESH_INTERVAL_MS = 2 * 60 * 60 * 1000 // 2 hours
export const REFRESH_INTERVAL_ASSET_PRICE_MS = 5 * 60 * 1000 // 5 minutes
export const FETCH_TIMEOUT_MS = 10 * 1000 // 10 seconds

export const swrParameters = {
    refreshInterval: REFRESH_INTERVAL_MS,
    revalidateOnMount: true,
    revalidateOnFocus: false,
}

// ---------- General Fetch ---------- //
export async function fetchApi(query, variables = {}, signal = undefined) {
    const timeoutController = new AbortController()
    const timeoutId = setTimeout(() => {
        timeoutController.abort()
    }, FETCH_TIMEOUT_MS)
    try {
        const response = await fetch(process.env.NEXT_PUBLIC_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Origin: 'https://www.stakingrewards.com',
                Referer: 'https://www.stakingrewards.com',
                'X-API-Key': process.env.NEXT_PUBLIC_API_KEY,
            },
            body: JSON.stringify({
                query,
                variables,
            }),
            signal: signal ?? timeoutController.signal,
        })

        const result = await response.json()
        if (response.ok) {
            return result?.data
        } else {
            throw new Error(`Something went wrong: ${response?.statusText}`)
        }
    } catch (err) {
        // Do not show the error when intentionally aborting (passing a signal, not timeout)
        if (!signal) {
            console.error(`Fetch error: ${err.message}`)
        }
        // throw err
    } finally {
        clearTimeout(timeoutId)
    }

    return null
}

export const getMetricsQuery = (metrics = []) => gql`
    metrics(limit: ${
        metrics?.length ?? 0
    }, where: { metricKeys: ${JSON.stringify(metrics)} }, showAll: true) {
        id
        label
        tooltip
        metricKey
        defaultValue
        changePercentages
        changeAbsolutes
        createdAt
        unit
        editableMin
        editableMax
        editableInterval
        variation
        isEditable
        approvedHistorical
    }
`

// ---------- ASSET DATA HOOKS ---------- //
const getTrendingAssetsSubQuery = (
    categoryKey,
    sortingMetricKey,
    limit,
    offset
) => {
    const tagsString = categoryKey
        ? `tags: { tagKeys: ["${categoryKey}"] }`
        : ''

    let additionalMetricFilter = ''
    if (sortingMetricKey === 'page_views_trend') {
        // Trending assets should have a positive trend
        additionalMetricFilter = 'defaultValue_gt: 0'
    }

    return gql`
        assets(
            where: {
                isLaunched: true,
                underMaintenance: false,
                excludeFromRanking: false,
                ${tagsString}
                metrics: {
                    metricKeys: ["${sortingMetricKey}"],
                    ${additionalMetricFilter}
                }
            }
            limit: ${limit}
            offset: ${offset}
            order: { metricKey_desc: "${sortingMetricKey}" }
        ) {
            id
            name
            slug
            symbol
            logoUrl
            ${getMetricsQuery([sortingMetricKey])}
        }
    `
}

export const useMostViewedAssets = (
    categoryKey = null,
    limit = 10,
    offset = 0
) => {
    const query = gql`{
        ${getTrendingAssetsSubQuery(
            categoryKey,
            'page_views_24h',
            limit,
            offset
        )}
    }`

    return useSWR([query, {}], {
        ...swrParameters,
        refreshInterval: REFRESH_INTERVAL_MS,
    })
}

export const useTrendingAssets = (
    categoryKey = null,
    limit = 10,
    offset = 0
) => {
    const query = gql`
        {
            ${getTrendingAssetsSubQuery(
                categoryKey,
                'page_views_trend',
                limit,
                offset
            )}
        }
    `

    return useSWR([query, {}], {
        ...swrParameters,
        refreshInterval: REFRESH_INTERVAL_MS,
    })
}

// ---------- ASSET PROFILE DATA HOOKS ---------- //
export const fetchSelectedAssetProfile = async (slug = '') => {
    let whereParams = {
        isActive: 'Any',
    }

    if (slug) {
        whereParams.slugs = [slug]
    } else {
        whereParams.tags = { tagKeys: ['proof-of-stake'] }
    }

    return await fetchApi(
        gql`
            query getSelectedAssetProfile($where: AssetsWhere!) {
                assets(where: $where, limit: 1) {
                    id
                    isActive
                    isLaunched
                    slug
                    name
                    includeInCalculator
                    tags {
                        id
                        tagKey
                        label
                    }
                    logoUrl
                    symbol
                    underMaintenance
                    links
                    description
                    introDescription
                    faqs {
                        question
                        answer
                        isOverview
                    }
                    metaWebApp
                    ${getMetricsQuery(['reward_rate'])}
                    rewardOptionsWithAssetAsOutput(limit: 1) {
                        id
                        label
                        rewardInfo
                        stakeableInApp
                        inputAssets(limit: 1) { slug } 
                        providers(limit: 1) {
                            logoUrl
                            name
                            slug
                            isVerified
                            isReferral
                        }
                        ${getMetricsQuery(['reward_frequency'])}
                    }
                }
            }
        `,
        {
            where: whereParams,
        }
    )
}

export const useProfileFaq = (
    type = TYPE_ASSET,
    slug = '',
    refreshInterval = REFRESH_INTERVAL_MS
) => {
    const query = gql`
        query getProfileFaq($slug: String!) {
            ${type}s(limit: 1, where: { slugs: [$slug], isActive: Any }) {
                id
                slug
                logoUrl
                name
                ${type === TYPE_ASSET ? 'symbol' : ''}
                faqs {
                    question
                    answer
                    ${type === TYPE_ASSET ? 'isOverview' : ''}
                }
            }
        }
    `
    const { data, isValidating } = useSWR([query, { slug }], {
        ...swrParameters,
        refreshInterval,
    })
    return {
        data: data?.[`${type}s`]?.[0],
        isLoading: isValidating && !data,
    }
}

export const fetchSoloStakingData = async (slug = '') => {
    const query = gql`
        query getSoloStakingAndHostingData($slug: String!) {
            rewardOptions(
                limit: 1,
                where: {
                    isActive: True
                    typeKeys: ["${SOLO_STAKING_KEY}"]
                    inputAsset: { slugs: [$slug] }
                },
                order: { metricKey_desc: "staked_tokens" }
            ) {
                id
                type {
                    key
                }
                ${getMetricsQuery(['reward_rate', 'minimum', 'lockup_time'])}
                inputAssets(limit: 1){
                    links
                }
            }
        }
    `
    const data = await fetchApi(query, { slug })
    return data
}

export const fetchExistingRewardOptionCategories = async (
    slug = '',
    supportedCategoryKeys = [],
    forType = TYPE_ASSET
) => {
    let categoryKeys = [...supportedCategoryKeys].filter(key => key !== ALL_KEY)
    const query = gql`
        query getCategoryRewardOptions($slug: String!) {
            ${categoryKeys
                .map(
                    key => gql`
                        ${`${toCamelCase(
                            key
                        )}DefaultRewardOption: rewardOptions(
                        limit: 1
                        where: {
                            isActive: True
                            typeKeys: ["${key}"]
                            ${
                                forType === TYPE_ASSET
                                    ? 'inputAsset: { slugs: [$slug] }, providers: { isActive: True }'
                                    : 'providers: { slugs: [$slug] }'
                            }
                        }
                    ) {
                        id
                        type {
                            key
                        }
                    }`}
                    `
                )
                .join(' ')}
        }
    `

    const data = await fetchApi(query, { slug })

    const optionCategoryKeys = categoryKeys.filter(expectedKey =>
        Boolean(
            data?.[`${toCamelCase(expectedKey)}DefaultRewardOption`]?.length
        )
    )
    return optionCategoryKeys?.length > 0
        ? [ALL_KEY, ...optionCategoryKeys]
        : []
}

export const useNetStakingFlowData = (
    slug = '',
    daysCount = 30,
    isAVS = false
) => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            const daysCountAgoDate = new Date(
                new Date().getTime() - daysCount * 24 * 60 * 60 * 1000
            )
                .toISOString()
                .slice(0, 10)
            const query = gql`query getNetStakingFlowData(){
                assets(
                    where: {
                        isLaunched: true
                        underMaintenance: false
                        excludeFromRanking: false
                        slugs: ["${slug}"]
                    }
                    limit: 1
                    offset: 0
                ) {
                    id
                    slug
                    metrics(showAll: true, limit: ${
                        daysCount + 1
                    }, where: { metricKeys: ["${
                        isAVS ? 'net_restaking_flow' : 'net_staking_flow'
                    }"], createdAt_gt: "${daysCountAgoDate}" }, interval: day) {
                        metricKey
                        createdAt
                        defaultValue
                        variation
                    }
                }
            }`
            const response = await fetchApi(query, {})
            setData(response)
            setIsLoading(false)
        }

        fetchData()
    }, [daysCount, isAVS, slug])

    return { data, isLoading }
}

// ---------- ASSET ARCHIVE DATA HOOKS ---------- //

const getAssetIconsStacksQuery = gql`query getAssetIconsStacksQuery() {
    ${ASSET_CATEGORIES.map(
        ({ key, slug }) => gql`
            ${toCamelCase(key)}: assets(
                limit: 5
                where: {
                    isLaunched: true
                    underMaintenance: false
                    tags: { tagKeys: ["${key}"] }
                }
                order: { metricKey_desc: "${
                    key === BITCOIN_AND_OTHERS_KEY
                        ? 'marketcap'
                        : 'staking_marketcap'
                }" }
            ) {
                id
                slug
                logoUrl
            }
            ${toCamelCase(key)}NativeAsset: assets(
                limit: 1
                where: {
                    slugs: ["${slug}"]
                }
            ) {
                id
                slug
                logoUrl
            }
        `
    ).join(' ')}
}`

export async function fetchAssetIconStacks() {
    return await fetchApi(getAssetIconsStacksQuery, {})
}

// ---------- PROVIDER PROFILE DATA HOOKS ---------- //

export const fetchSelectedProviderProfile = async (slug = '') => {
    let whereParams = {
        isClaimed: true,
    }

    if (slug) {
        whereParams.slugs = [slug]
    } else {
        whereParams.typeKeys = [POS_PROVIDER_KEY]
    }

    return await fetchApi(
        gql`
            query getSelectedProviderProfile($where: ProvidersWhere!) {
                providers: providers(where: $where, limit: 1) {
                    id
                    slug
                    name
                    type {
                        key
                    }
                    logoUrl
                    country
                    links
                    isReferral
                    likes
                    isClaimed
                    isVerified
                    links
                    operationInitiationDate
                    team
                    faqs {
                        question
                        answer
                    }
                    rewardOptions(
                        limit: 1
                        where: { inputAsset: { isActive: False } }
                    ) {
                        id
                    }
                }
            }
        `,
        {
            where: whereParams,
        }
    )
}

// ---------- PERFORMANCE CHART METRICS  ----------//

export const fetchApprovedHistoricalMetrics = async (
    id = '',
    type = TYPE_ASSET,
    metricKeys = []
) => {
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - 30)
    const initialDateStr = startDate.toISOString().split('T')[0] // 30 days ago
    const query = gql`
        query getExistingMetrics($metricKeys: [String!], $id: String!, $limit: Int!) {
            ${type}s(where: { ids: [$id] }, limit: 1) {
                metrics(
                    limit: $limit,
                    where: {
                        metricKeys: $metricKeys,
                        createdAt_gt: "${initialDateStr}T00:00:00Z"
                    },
                    showAll: true
                    interval: day
                    order: { createdAt: desc }
                    pickItem: last
                ) {
                    metricKey
                    defaultValue
                    createdAt
                    approvedHistorical
                }
            }
        }
    `
    return await fetchApi(query, {
        metricKeys,
        id,
        limit: metricKeys?.length ?? 0,
    })
}

export const fetchPerformanceChartMetrics = async (
    id = '',
    type = TYPE_ASSET,
    metricKeys = [],
    startDate = new Date(),
    endDate = new Date()
) => {
    // NB: Fetch one day before and after the selected dates to get the first and last values
    // (since API allows only greater than and less than comparisons)
    const initialDate = new Date(startDate)
    initialDate.setDate(initialDate.getDate() - 1)
    const finalDate = new Date(endDate)
    finalDate.setDate(finalDate.getDate() + 1)

    const getDatePairsBy500Days = (
        startDate = new Date(),
        endDate = new Date(),
        metricsCount = 0
    ) => {
        const totalNumberOfDays = getDaysBetweenDates(startDate, endDate)
        const availableDaysCount = Math.floor(500 / metricsCount)

        const datePairs = []
        let startNumberOfDays = totalNumberOfDays
        let firstDate = new Date(startDate)

        while (startNumberOfDays > 0) {
            if (startNumberOfDays <= availableDaysCount) {
                let secondDate = new Date(firstDate)
                secondDate.setDate(secondDate.getDate() + startNumberOfDays)
                datePairs.push({
                    startDate: new Date(firstDate),
                    endDate: new Date(secondDate),
                    daysCount: startNumberOfDays,
                })
                break
            } else {
                let secondDate = new Date(firstDate)
                secondDate.setDate(secondDate.getDate() + availableDaysCount)
                datePairs.push({
                    startDate: new Date(firstDate),
                    endDate: new Date(secondDate),
                    daysCount: availableDaysCount,
                })
                startNumberOfDays -= availableDaysCount
                firstDate = new Date(secondDate)
            }
        }

        return datePairs
    }

    // NB: 500 data points is possible at a time.
    // Depending how many metrics are selected, several fetches should be performed.
    const fetchPromises = getDatePairsBy500Days(
        initialDate,
        finalDate,
        metricKeys.length
    ).map(({ startDate, endDate, daysCount }) => {
        const query = gql`
            query getChartMetrics($metricKeys: [String!], $id: String!) {
                ${type}s(where: { ids: [$id] }, limit: 1) {
                    slug
                    metrics(limit: ${daysCount * metricKeys.length},
                        where: {
                            metricKeys: $metricKeys,
                            createdAt_gt: "${formatDateToISO(
                                startDate
                            )}T00:00:00Z",
                            createdAt_lt: "${formatDateToISO(
                                endDate
                            )}T00:00:00Z"
                        },
                        interval: day
                        order: { createdAt: desc }
                        showAll: true
                        pickItem: last
                    ){
                        metricKey
                        defaultValue
                        createdAt
                        approvedHistorical
                    }
                }
            }
        `
        return fetchApi(query, { metricKeys, id })
    })

    return await Promise.all(fetchPromises)
        .then(list => {
            let mergedResult = null
            list.forEach(result => {
                const asset = result?.[`${type}s`]?.[0]
                if (asset) {
                    if (!mergedResult) {
                        mergedResult = { ...asset }
                    } else {
                        const metrics = asset?.metrics ?? []
                        mergedResult = {
                            ...mergedResult,
                            metrics: [
                                ...(mergedResult?.metrics ?? []),
                                ...metrics,
                            ],
                        }
                    }
                }
            })
            return mergedResult
        })
        .catch(error => {
            console.error(error)
            return null
        })
}

// ---------- GLOBAL DATA HOOKS ----------//

export function useGlobalData(metrics = []) {
    const query = gql`
        query getGlobalData() {
            ${getMetricsQuery(metrics)}
        }
    `
    return useSWR([query, {}], swrParameters)
}

// ---------- SEARCH HOOK ----------//

export function useSearchQuery(searchTerms = '', limit = 20) {
    const [isSearching, setIsSearching] = useState(false)
    const [data, setData] = useState(null)

    useEffect(() => {
        const abortController = new AbortController()
        const { signal } = abortController

        async function fetchData() {
            if (searchTerms !== '') {
                setIsSearching(true)
                const data = await fetchApi(
                    gql`
                        query getSearch($query: String!, $limit: Int!) {
                            search(query: $query, limit: $limit) {
                                entityType
                                entityID
                                entityMeta
                            }
                        }
                    `,
                    {
                        query: searchTerms,
                        limit,
                    },
                    signal
                )
                setData(data)
                setIsSearching(false)
            } else {
                setData(null)
            }
        }
        fetchData()

        return () => {
            abortController.abort()
        }
    }, [limit, searchTerms])

    return { data, isSearching }
}

// ---------- CALCULATOR QUERIES ----------//

const assetMetricsQuery = getMetricsQuery([
    'reward_rate',
    'staking_marketcap',
    'marketcap',
    'price',
    'inflation_rate',
    'total_supply',
    'circulating_supply',
])

const assetContentQuery = gql`
    id
    slug
    name
    tags {
        tagKey
    }
    logoUrl
    symbol
    inflationFormula
    isActive
    isLaunched
    ${assetMetricsQuery}
`

export const providerQuery = gql`
providers(limit: 2) {
    id
    slug
    name
    country
    logoUrl
    type {
        key
    }
    isVerified
    isActive
    isClaimed
    isReferral
}
`

export const providerQueryWithMetrics = gql`
providers(limit: 2) {
    id
    slug
    name
    country
    logoUrl
    links
    type {
        key
    }
    isVerified
    isActive
    isClaimed
    isReferral
    metrics(where: {metricKeys:["vsp"]},limit:100) {
        metricKey
        defaultValue
        variation
    }
}
`

const validatorsQuery = gql`
    validators(
        limit: 2, 
        where: { 
            isActive: True, 
            status: { labels: ["active", "standby"] } 
        }, 
        order: { status: desc, metricKey_desc: "reward_rate" }
    ) {
        id
        address
        isActive
        status {
            label
        }
        ${getMetricsQuery(['reward_rate', 'reward_frequency'])}
    }
`

export const relatedAssetOfRewardOptionQuery = gql`
    inputAssets(limit: 1) {
        id
        slug
        name
        logoUrl
        ${getMetricsQuery(['price'])}
    }
`

const categoryAssetsQuery = gql`
    query getCategoryAssets() {
        ${ASSET_CATEGORIES.map(
            ({ key }) => gql`
                ${toCamelCase(key)}Asset: assets(
                    limit: 1
                    where: {
                        isLaunched: true
                        underMaintenance: false
                        includeInCalculator: true
                        tags: { tagKeys: ["${key}"] }
                    }
                    order: { metricKey_desc: "page_views_24h" }
                ) {
                    ${assetContentQuery}
                }
            `
        ).join(' ')}
    }
`

export const useExistingAssetCategories = () => {
    const { data, isValidating } = useSWR(
        [categoryAssetsQuery, {}],
        swrParameters
    )

    const hasNoZeroRewardRateAsset = asset =>
        (asset?.metrics?.find(metric => metric?.metricKey === 'reward_rate')
            ?.defaultValue ?? 0) > 0

    const existingAssetCategories = ASSET_CATEGORIES.map(
        ({ key }) => key
    ).filter(key =>
        hasNoZeroRewardRateAsset(data?.[`${toCamelCase(key)}Asset`]?.[0])
    )

    const defaultAsset = data?.[`${toCamelCase('proof-of-stake')}Asset`]?.[0]

    const isLoading = isValidating && !data

    return {
        defaultAsset: isLoading ? undefined : defaultAsset,
        existingAssetCategories: isLoading
            ? undefined
            : existingAssetCategories,
    }
}

export const fetchRewardOptionCategoriesForCalculator = async (slug = '') => {
    const rewardOptionCategoriesPerAsset =
        slug === 'autonity'
            ? PROVIDER_CATEGORIES.map(cat => ({ ...cat, withProvider: false }))
            : PROVIDER_CATEGORIES
    const categoryRewardOptionsQuery = gql`
        query getCategoryRewardOptions($slug: String!) {
            ${rewardOptionCategoriesPerAsset
                .map(
                    ({ key, withProvider, hasStakedTokens }) => gql`
                    ${toCamelCase(key)}RewardOption: rewardOptions(
                        limit: 1
                        where: {
                            isActive: True
                            typeKeys: ["${key}"]
                            inputAsset: { slugs: [$slug] }
                            ${
                                withProvider
                                    ? 'providers: { isClaimed: true, isActive: True }'
                                    : ''
                            }
                            metrics: { metricKeys: ["reward_rate"], defaultValue_gt: 0 }
                        }
                        order: {
                            validatorStatus: desc,
                            ${withProvider ? 'providerIsVerified: desc,' : ''}
                            metricKey_desc: "${
                                hasStakedTokens
                                    ? 'staked_tokens'
                                    : 'reward_rate'
                            }" 
                        }
                    ) {
                        id
                        type {
                            key
                        }
                        label
                        rewardFormula
                        ${relatedAssetOfRewardOptionQuery}
                        ${providerQuery}
                        ${getMetricsQuery([
                            'reward_rate',
                            'reward_frequency',
                            'staked_tokens',
                            'minimum',
                        ])}
                        ${validatorsQuery}
                    }
                `
                )
                .join(' ')}
        }
    `
    const data = await fetchApi(categoryRewardOptionsQuery, { slug })

    const existingRewardOptionCategories = PROVIDER_CATEGORIES.map(
        ({ key }) => key
    ).filter(key => {
        return Boolean(data?.[`${toCamelCase(key)}RewardOption`]?.[0])
    })

    // Get default reward options of existing categories
    const options =
        existingRewardOptionCategories
            .map(key => data?.[`${toCamelCase(key)}RewardOption`]?.[0])
            ?.filter(option => Boolean(option)) ?? []

    let defaultRewardOption
    const liquidStakingOption = options.find(
        opt => opt?.type?.key === LIQUID_STAKING_PROVIDER_KEY
    )
    if (slug === 'ethereum-2-0' && liquidStakingOption) {
        defaultRewardOption = liquidStakingOption
    } else {
        const verifiedOptions = options.filter(
            opt => opt?.providers?.[0]?.isVerified
        )
        let optionsToFilter = []
        if (verifiedOptions.length > 0) {
            optionsToFilter = verifiedOptions
        } else {
            optionsToFilter = options
        }

        // Default is chosen by the highest staked_tokens, otherwise by the highest reward_rate.
        const getMaxMetricValueOption = metricKey =>
            optionsToFilter
                .filter(option =>
                    Boolean(getMetricValueByKey(option, metricKey))
                )
                .reduce((acc, curr) => {
                    if (acc === null) {
                        return curr
                    }

                    if (
                        getMetricValueByKey(curr, metricKey) >
                        getMetricValueByKey(acc, metricKey)
                    ) {
                        return curr
                    } else {
                        return acc
                    }
                }, null)

        defaultRewardOption = getMaxMetricValueOption('staked_tokens') ?? null
        if (!defaultRewardOption) {
            defaultRewardOption = getMaxMetricValueOption('reward_rate') ?? null
        }
    }

    return {
        existingRewardOptionCategories,
        defaultRewardOption,
    }
}

export const fetchAssetsBySlugs = async (slugs = []) => {
    return await fetchApi(
        gql`
            query getSelectedAsset($where: AssetsWhere!, $limit: Int!) {
                assets(
                    where: $where
                    limit: $limit
                ) {
                    ${assetContentQuery}
                }
            }
        `,
        {
            where: {
                slugs,
            },
            limit: slugs?.length ?? 0,
        }
    )
}

export const fetchAssetsBySearchQuery = async (
    searchTerms = '',
    includePrice = false
) => {
    const metrics = includePrice
        ? `metrics (where: {metricKeys: ["price"]},limit:1) {
            label
            defaultValue
        }`
        : ''
    return await fetchApi(
        gql`
            query getAssetsForFilter($where: AssetsWhere!) {
                assets(
                    where: $where
                    order: { metricKey_desc: "page_views_24h" }
                    limit: 10
                ) {
                    id
                    slug
                    name
                    logoUrl
                    symbol
                    ${metrics}
                }
            }
        `,
        {
            where: {
                isLaunched: true,
                underMaintenance: false,
                search: searchTerms,
            },
        }
    )
}

export const fetchSelectedAsset = async (slug = '', customWhere = {}) => {
    const identifierWhere = slug ? { slugs: [slug] } : {}

    let whereParams = {
        ...identifierWhere,
        isLaunched: true,
        underMaintenance: false,
        isActive: 'Any',
        ...customWhere,
    }

    return await fetchApi(
        gql`
            query getSelectedAsset($where: AssetsWhere!) {
                assets(
                    where: $where
                    order: { metricKey_desc: "page_views_24h" }
                    limit: 1
                ) {
                    ${assetContentQuery}
                }
            }
        `,
        {
            where: whereParams,
        }
    )
}

export function usePaginatedItems(
    entity,
    wherePayload = {
        assetSlug: '',
        rewardOptionId: '',
        search: '',
        category: null,
    },
    limit = PAGE_SIZE_20
) {
    let query = ''
    let variables = {
        limit,
    }
    if (entity === TYPE_ASSET) {
        const tagsString = Boolean(wherePayload?.category)
            ? `tags: { tagKeys: ["${wherePayload?.category}"] }`
            : ''
        query = gql`
            query getPaginatedAssets(   
                $limit: Int!
                $offset: Int!
            ) {
                assets(
                    where: {
                        isLaunched: true
                        underMaintenance: false
                        includeInCalculator: true
                        ${tagsString}
                        search: "${wherePayload?.search}"
                    }
                    order: { metricKey_desc: "page_views_24h" }
                    limit: $limit
                    offset: $offset
                ) {
                    ${assetContentQuery}
                }
            }
        `
    } else if (entity === TYPE_PROVIDER) {
        const typeString = Boolean(wherePayload?.category)
            ? `typeKeys: ["${wherePayload?.category}"]`
            : ''

        const withProvider =
            ![SOLO_STAKING_KEY, SMART_CONTRACT_KEY].includes(
                wherePayload?.category
            ) && wherePayload?.assetSlug !== 'autonity'
        let filtersString = ''
        if (!withProvider) {
            filtersString = '' // NB: search is performed only by providers, RO labels are not searchable
        } else {
            filtersString = `providers: { isActive: True, isClaimed: true, search: "${wherePayload?.search}" }`
        }

        const hasStakedTokens = wherePayload?.category
            ? Boolean(
                  PROVIDER_CATEGORIES.find(
                      category => category?.key === wherePayload?.category
                  )?.hasStakedTokens
              )
            : false
        const metricKeyDesc = hasStakedTokens ? 'staked_tokens' : 'reward_rate'
        const orderString = `order: { validatorStatus: desc, ${
            withProvider ? 'providerIsVerified: desc,' : ''
        } metricKey_desc: "${metricKeyDesc}" }`
        query = gql`
            query getPaginatedRewardOptions(
                $assetSlug: String!
                $limit: Int!
                $offset: Int!
            ) {
                rewardOptions(
                    limit: $limit
                    offset: $offset
                    where: {
                        isActive: True
                        inputAsset: { slugs: [$assetSlug] }
                        ${typeString}
                        ${filtersString}
                        metrics: { metricKeys: ["reward_rate"], defaultValue_gt: 0 }
                    }
                    ${orderString}
                ) {
                    id
                    type {
                        key
                    }
                    label
                    rewardFormula
                    stakeableInApp
                    ${relatedAssetOfRewardOptionQuery}
                    ${providerQuery}
                    ${getMetricsQuery([
                        'reward_rate',
                        'reward_frequency',
                        'minimum',
                    ])}
                    ${validatorsQuery}
                    outputAssets (limit:1) {
                        name
                        slug
                        logoUrl
                    }
                }
            }
        `
        variables = { ...variables, assetSlug: wherePayload.assetSlug }
    } else if (entity === TYPE_VALIDATOR) {
        query = gql`
            query getPaginatedValidators(
                $rewardOptionId: String!
                $limit: Int!
                $offset: Int!
            ) {
                validators(
                    limit: $limit, 
                    offset: $offset, 
                    where: { 
                        isActive: True, 
                        status: { labels: ["active", "standby"] }, 
                        rewardOptions: { ids: [$rewardOptionId] }
                    }, 
                    order: { status: desc, metricKey_desc: "reward_rate" }
                ) {
                    id
                    address
                    isActive
                    status {
                        label
                    }
                    ${getMetricsQuery(['reward_rate', 'reward_frequency'])}
                }
            }
        `
        variables = {
            ...variables,
            rewardOptionId: wherePayload.rewardOptionId,
        }
    }

    return useSWRInfinite(
        index => {
            variables = { ...variables, offset: index * limit }
            return [query, variables]
        },
        null,
        { ...swrParameters, revalidateFirstPage: false }
    )
}

export const useProfileMetrics = (
    type = TYPE_ASSET,
    slug = '',
    metricKeys = [],
    refreshInterval = REFRESH_INTERVAL_MS
) => {
    const query = gql`
        query getProfileMetrics($slug: String!) {
            ${type}s(limit: 1, where: { slugs: [$slug], isActive: Any }) {
                id
                slug
                logoUrl
                name
                ${type === TYPE_ASSET ? 'symbol' : ''}
                ${getMetricsQuery(metricKeys)}
            }
        }
    `
    const { data, isValidating } = useSWR(slug ? [query, { slug }] : null, {
        ...swrParameters,
        refreshInterval,
    })
    return {
        data: data?.[`${type}s`]?.[0],
        isLoading: isValidating && !data,
    }
}

export const fetchProfileMetrics = (
    type = TYPE_ASSET,
    slug = '',
    metricKeys = []
) => {
    const query = gql`
        query getProfileMetrics($slug: String!) {
            ${type}s(limit: 1, where: { slugs: [$slug], isActive: Any }) {
                id
                slug
                logoUrl
                name
                ${type === TYPE_ASSET ? 'symbol' : ''}
                ${getMetricsQuery(metricKeys)}
            }
        }
    `

    const variables = {
        slug,
    }

    return fetchApi(query, variables)
}

export const useProfileMetricsWithMinMax = (
    type = TYPE_ASSET,
    slug = '',
    metricKeys = [],
    refreshInterval = REFRESH_INTERVAL_MS
) => {
    const getWhereString = typeInRange => {
        const tagsString =
            typeInRange === 'current'
                ? ''
                : `tags: { tagKeys: ${JSON.stringify(
                      ASSET_CATEGORIES.map(cat => cat.key)
                  )} }`
        return type === TYPE_ASSET
            ? `isLaunched: true, underMaintenance: false, excludeFromRanking: false, ${tagsString}`
            : `isActive: True, isClaimed: true`
    }

    const query = gql`
        query getProfileMetrics($slug: String!) { 
        ${metricKeys
            .map(metricKey => {
                const listOfRanges = isMetricWithExpansion(metricKey)
                    ? ['min', 'current', 'max']
                    : ['current']
                return gql`
                    ${listOfRanges
                        .map(key => {
                            const fullKey = `${key}_${metricKey}`
                            return gql`
                                ${`${fullKey}: 
                                ${type}s(
                                    limit: 1,
                                    where: { metrics: { metricKeys: ["${metricKey}"] }, ${getWhereString(
                                        key
                                    )}, ${
                                        key === 'current'
                                            ? 'slugs: [$slug]'
                                            : ''
                                    } }
                                    ${
                                        key !== 'current'
                                            ? `order: { metricKey_${
                                                  key === 'min' ? 'asc' : 'desc'
                                              }: "${metricKey}" }`
                                            : ''
                                    }
                                ) {
                                    id
                                    slug
                                    logoUrl
                                    name
                                    ${getMetricsQuery([metricKey])}
                                }`}
                            `
                        })
                        .join(' ')}
                `
            })
            .join(' ')}
    }`

    const { data, isValidating } = useSWR([query, { slug }], {
        ...swrParameters,
        refreshInterval,
    })
    return {
        data,
        isLoading: isValidating && !data,
    }
}

export const useProfileMetricsWithMinMaxExtended = (
    type = TYPE_ASSET,
    metricGroup = null,
    item = null,
    refreshInterval = REFRESH_INTERVAL_MS,
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    isPercentage = false,
    byChange = false
) => {
    const metricKeys = [metricGroup?.key]

    const whereString =
        type === TYPE_ASSET
            ? `isLaunched: true, underMaintenance: false, excludeFromRanking: false, tags: { tagKeys: ${JSON.stringify(
                  ASSET_CATEGORIES.map(cat => cat.key)
              )} }`
            : `isActive: True, isClaimed: true`

    const changeAbsolutesOrPercentages = isPercentage
        ? 'changeAbsolutes'
        : 'changePercentages'

    const changesSortBy = byChange
        ? `${changeAbsolutesOrPercentages}Key: _${timeframeKey}`
        : ''

    const query = gql`
        query getProfileMetricsExtended() { 
        ${metricKeys
            .map(metricKey => {
                const currentValue = byChange
                    ? getMetricPercentage(
                          item,
                          metricKey,
                          timeframeKey,
                          isPercentage ? '%' : ''
                      )
                    : getMetricDefaultValueByKey(item, metricKey)
                return gql`
                    ${['min', 'lt', 'gte', 'max']
                        .map(key => {
                            const isComparisonKey = ['lt', 'gte'].includes(key)
                            if (
                                // NB: Since it is not possible to compare variations, skip the comparison
                                // for net_staking_flow.
                                isComparisonKey &&
                                (isInvalidNumber(currentValue) ||
                                    metricKey === 'net_staking_flow')
                            ) {
                                return ''
                            }

                            let filtersString = ''
                            if (isComparisonKey) {
                                const postfixMap = {
                                    lt: '_lt',
                                    gte: '_gte',
                                }
                                const comparisonPostfix = postfixMap[key] || ''
                                const valueTypeToCompare = byChange
                                    ? changeAbsolutesOrPercentages.slice(0, -1)
                                    : 'defaultValue'
                                const valueToCompare = byChange
                                    ? `{ key: _${timeframeKey}, value: ${currentValue} }`
                                    : currentValue

                                filtersString = `metrics: { ${valueTypeToCompare}${comparisonPostfix}: ${valueToCompare}, metricKeys: ["${metricKey}"] }`
                            } else {
                                filtersString = `metrics: { metricKeys: ["${metricKey}"] }`
                            }
                            return gql`
                                ${`${key}_${metricKey}: 
                            ${type}s(
                                limit: 5,
                                where: { ${whereString}, ${filtersString} }
                                ${`order: { metricKey_${
                                    key === 'min' || key === 'gte'
                                        ? 'asc'
                                        : 'desc'
                                }: "${metricKey}" ${changesSortBy} }`}
                            ) {
                                id
                                slug
                                logoUrl
                                name
                                symbol
                                ${getMetricsQuery([metricKey])}
                            }`}
                            `
                        })
                        .join(' ')}
                `
            })
            .join(' ')}
    }`

    const { data, isValidating } = useSWR([query, {}], {
        ...swrParameters,
        refreshInterval,
    })
    return {
        data,
        isLoading: isValidating && !data,
    }
}

export const useTrendingAsset = (
    slug = '',
    refreshInterval = REFRESH_INTERVAL_MS
) => {
    const query = gql`
        query getTrendingAssetForProvider($slug: String!) {
            rewardOptions(
                where: { providers: { slugs: [$slug] } }
                order: { metricKey_desc: "net_staking_flow_7d" }
                limit: 1
            ) {
                inputAssets(limit: 1) {
                    logoUrl
                    name
                    symbol
                }
                ${getMetricsQuery(['net_staking_flow_7d'])}
            }
        }
    `
    const { data, isValidating } = useSWR([query, { slug }], {
        ...swrParameters,
        refreshInterval,
    })
    const isLoading = isValidating && !data
    return { data, isLoading }
}

export const fetchFormulaMetricsFromAsset = async (
    slug = '',
    metricKeys = []
) => {
    const query = gql`
        query getFormulaMetricsFromAsset(
            $slug: String!
        ) {
            assets(limit: 1, where: { slugs: [$slug] }) {
                ${getMetricsQuery(metricKeys)}
            }
        }
    `
    return await fetchApi(query, {
        slug,
    })
}

export const fetchFormulaMetricsFromRewardOption = async (
    id = '',
    metricKeys = []
) => {
    const query = gql`
        query getFormulaMetricsFromRewardOption(
            $id: String!
        ) {
            rewardOptions(limit: 1, where: { ids: [$id] }) {
                ${getMetricsQuery(metricKeys)}
            }
        }
    `
    return await fetchApi(query, {
        id,
    })
}

export const fetchFormulaMetrics = async (
    rewardOptionId = '',
    metricKeys = []
) => {
    const query = gql`
        query getFormulaMetricsFromOption(
            $id: String!
        ) {
            rewardOptions(limit: 1, where: { ids: [$id] }) {
                ${getMetricsQuery(metricKeys)}
            }
        }
    `
    return await fetchApi(query, {
        id: rewardOptionId,
    })
}

export const useIsWithPerformanceMetric = (type = TYPE_ASSET, slug = '') => {
    const [withPerformanceMetric, setWithPerformanceMetric] = useState(false)

    useEffect(() => {
        const filterString = `${
            type === TYPE_ASSET ? 'inputAsset' : 'providers'
        }: { slugs: ["${slug}"] }`

        const query = gql`
            {
                rewardOptions(limit: 10, order: { metricKey_desc: "staked_tokens" }, where: {
                    isActive: True,
                    ${filterString}
                } ) {
                    id
                    metrics(limit: 1, where: { metricKeys: ["performance"] }, showAll: true) {
                        metricKey
                        defaultValue
                    }
                }
            }
        `

        async function fetchData() {
            const data = await fetchApi(query, {})
            setWithPerformanceMetric(
                data?.rewardOptions.filter(
                    item =>
                        !isInvalidNumber(
                            getMetricValueByKey(item, 'performance')
                        )
                ).length >= 5
            )
        }
        fetchData()
    }, [slug, type])

    return withPerformanceMetric
}

export const fetchProviders = async () => {
    const query = gql`
        {
            providers(limit: 500, where: { isClaimed: true }) {
                slug
            }
        }
    `

    const data = await fetchApi(query)
    return data.providers
}

// Helper function to create asset fetch promises
const createAssetFetchPromises = async (totalAssets, limit) => {
    let promises = []
    for (let offset = 0; offset < totalAssets; offset += limit) {
        const query = gql`
        {
          assets(where:{isActive: Any}, limit: ${limit}, offset: ${offset}) {
            slug
          }
        }
      `
        promises.push(fetchApi(query))
    }
    return promises
}

// Function to fetch all assets concurrently
export const fetchAllAssetsConcurrently = async () => {
    const totalAssets = 13000 // Total assets to fetch, currently at about 12500, we might need to adjust this value
    const limit = 500
    const assetPromises = await createAssetFetchPromises(totalAssets, limit)

    const assetsResults = await Promise.all(assetPromises)
    const allAssets = assetsResults.flatMap(result => result?.assets)
    return allAssets
}

// Fetching both assets and providers concurrently
export const fetchSitemapDataConcurrently = async () => {
    const assetsPromise = fetchAllAssetsConcurrently()
    const providersPromise = fetchProviders()

    const [assets, providers] = await Promise.all([
        assetsPromise,
        providersPromise,
    ])
    return { assets, providers }
}

export const useUntracketAssetProfile = (slug = '') => {
    const query = gql`
        query getUntrackedAssetProfile()
        {
            assets(where: {isActive: Any, slugs: ["${slug}"]}, limit: 500) {
              name
              slug
              description
              symbol
              faqs {
                question
                answer
                isOverview
              }
              metrics (where: {metricKeys: ["price", "daily_trading_volume", "marketcap"]},limit:4) {
                  metricKey
                  defaultValue
                  changeAbsolutes
                  changePercentages
              }
              tags {
                tagKey
              }
            }
          }
        `
    return useSWR([query, {}], {
        ...swrParameters,
        refreshInterval: REFRESH_INTERVAL_ASSET_PRICE_MS,
    })
}

export const useAssetsForRates = (slug = '') => {
    const query = gql`
        query getAssetsForRates()
        {
            assets(where: {isActive: Any, slugs:["${slug}", "stasis-euro", "bitcoin", "ethereum-2-0"]}, limit: 500) {
              logoUrl
              symbol
              slug
              name
              metrics (where: {metricKeys: ["price"]},limit:1) {
                  label
                  defaultValue
              }
            }
          }
        `
    return useSWR([query, {}], {
        ...swrParameters,
        refreshInterval: REFRESH_INTERVAL_ASSET_PRICE_MS,
    })
}

export const fetchSimilarAssets = (
    asset = null,
    sortByMetricKey = '',
    allMetricKeys = []
) => {
    const metricValue = getMetricValueByKey(asset, sortByMetricKey)

    if (isInvalidNumber(metricValue)) {
        return []
    }

    const metricsQuery = getMetricsQuery(allMetricKeys)

    return fetchApi(
        gql`
        query getSimilarAssets()
        {
            currentAsset: assets(where: {isActive: Any, slugs: ["${asset?.slug}"]}, order: {metricKey_desc: "staking_marketcap"}, limit: 1, offset: 0) {
                name
                symbol
                slug
                logoUrl
                ${metricsQuery}
            }
            twoBefore: assets(where: {isActive: True, underMaintenance: false, isLaunched: true, metrics: { metricKeys: ["${sortByMetricKey}"], defaultValue_lt: ${metricValue} }}, order: {metricKey_desc: "${sortByMetricKey}"}, limit: 2, offset: 0) {
                name
                symbol
                slug
                logoUrl
                ${metricsQuery}
            }
            twoAfter: assets(where: {isActive: True, underMaintenance: false, isLaunched: true, metrics: { metricKeys: ["${sortByMetricKey}"], defaultValue_gt: ${metricValue} }}, order: {metricKey_asc: "${sortByMetricKey}"}, limit: 2, offset: 0) {
                name
                symbol
                slug
                logoUrl
                ${metricsQuery}
            }
          }
        `
    )
}

export const fetchPopularAssets = (
    sortByMetricKey = '',
    allMetricKeys = []
) => {
    return fetchApi(
        gql`
        query getPopularAssets()
        {
            assets(
                limit: 4,
                order: { metricKey_desc: "${sortByMetricKey}" }
            ) {
                id
                name
                slug
                symbol
                logoUrl
                ${getMetricsQuery(allMetricKeys)}
            }
          }
        `
    )
}
export const fetchSrethPageImages = () => {
    const query = gql`
        {
            assets(
                limit: 4
                where: {
                    slugs: [
                        "origin-ether"
                        "wrapped-steth"
                        "rocket-pool-eth"
                        "staked-frax-ether"
                    ]
                }
            ) {
                slug
                logoUrl
            }
            providers(limit: 1, where: { slugs: ["convex"] }) {
                slug
                logoUrl
            }
        }
    `

    return fetchApi(query)
}

export const fetchAssetSymbolAndLogo = async (slugs = []) => {
    const variables = {
        slugs,
    }
    const query = gql`
        query getAssetSymbolAndLogo($slugs: [String!]) {
            assets(where: { slugs: $slugs, isActive: Any }, limit: 99) {
                slug
                symbol
                logoUrl
            }
        }
    `
    return fetchApi(query, variables)
}

export const fetchLSTLogosForAVSProfile = slugs => {
    const variables = {
        slugs,
    }
    const query = gql`
        query lstLogos($slugs: [String!]) {
            assets(limit: 99, where: { isActive: Any, slugs: $slugs }) {
                slug
                logoUrl
                name
                symbol
            }
        }
    `

    return fetchApi(query, variables)
}

export const fetchAVSLogosForAVSProfile = () => {
    const query = gql`
        query AVSLogosForAVSProfile() {
            assets(limit: 500, where: { isActive: Any, tags: { tagKeys: ["${AVS_KEY}"] } }) {
                slug
                name
                logoUrl
                symbol
            }
        }
    `

    return fetchApi(query)
}

export const useAssetsWithMetrics = (slugs = [], metricKeys = []) => {
    const query = gql`
        query getAssetsWithMetrics($slugs: [String!]) {
            assets(where: { slugs: $slugs, isActive: Any }, limit: ${
                slugs?.length ?? 0
            }) {
                slug
                symbol
                logoUrl
                name
                metaWebApp
                ${getMetricsQuery(metricKeys)}
            }
        }
    `
    const shouldFetch = !!slugs?.length

    return useSWR(shouldFetch ? [query, { slugs }] : null, {
        ...swrParameters,
        refreshInterval: REFRESH_INTERVAL_ASSET_PRICE_MS,
    })
}

// Used for expanded LST reward option row
export const fetchAdditionalRewardOptions = slugs => {
    const variables = {
        slugs,
    }
    const query = gql`
        query getAdditionalRewardOptionsForLSTRow($slugs: [String!]) {
            rewardOptions(
                where: {
                    inputAsset: { slugs: $slugs }
                    isActive: True
                    metrics: { metricKeys: ["reward_rate"] }
                }
                order: { metricKey_desc: "reward_rate" }
                limit: 500
            ) {
                label
                type {
                    label
                }
                links
                chains {
                    key
                    label
                }
                metrics(
                    limit: 10
                    where: {
                        metricKeys: [
                            "reward_rate"
                            "staked_tokens"
                            "staking_share"
                        ]
                    }
                ) {
                    defaultValue
                    metricKey
                    variation
                }
                providers(limit: 2) {
                    name
                    type {
                        label
                    }
                    logoUrl
                    isVerified
                }
            }
        }
    `
    return fetchApi(query, variables)
}

export const useStakeableAssetsWithMetrics = (
    metricKeys = [],
    isInput = false
) => {
    // TODO: this would benefit from pagination on the backend side
    const query = gql`
        query getAssetsWithMetrics() {
            assets(where: { isActive: Any, rewardOptionsWithAssetAs${
                isInput ? 'Input' : 'Output'
            }: { stakeableInApp: true } }, limit: 100) {
                slug
                symbol
                logoUrl
                name
                metaWebApp
                ${getMetricsQuery(metricKeys)}
                rewardOptionsWithAssetAs${
                    isInput ? 'Input' : 'Output'
                }(limit: 1) {
                    id
                    label
                    rewardInfo
                    stakeableInApp
                    inputAssets(limit: 1) { slug }
                    ${getMetricsQuery(['reward_rate'])}
                    providers(limit: 1) {
                        logoUrl
                        name
                        slug
                        isVerified
                        isReferral
                    }
                }
            }
        }
    `

    return useSWR([query, {}], {
        ...swrParameters,
        refreshInterval: REFRESH_INTERVAL_ASSET_PRICE_MS,
    })
}

export const fetchProviderCountForAssets = async (slugs = []) => {
    const getSubQueryForSingleAsset = slug => {
        // gql doesnt support dashes in aliases, so we replace them with underscores
        return `
            ${slug.replaceAll('-', '__')}:
                meta {
                        providers(
                        where: { rewardOptions: { inputAsset: { slugs: ["${slug}"] } } }
                    ) {
                        count
                    }
                }
        `
    }

    const query = gql`
        query fetchProviderCountForAssets() {
            ${slugs.map(getSubQueryForSingleAsset).join(' ')}
        }
    `
    return fetchApi(query)
}

export const fetchRewardOptionsForAssets = async (slugs = []) => {
    const getSubQueryForSingleAsset = slug => {
        // gql doesnt support dashes in aliases, so we replace them with underscores
        return `
            ${slug.replaceAll('-', '__')}:
            rewardOptions(where: {inputAsset: {slugs: ["${slug}"] }}, limit: 500) {
                providers(limit: 5) {
                    id
                    name
                    logoUrl
                    slug
                }
                metrics(where: {metricKeys:["reward_rate"]}, limit:1) {
                    metricKey
                    defaultValue
                }
            }
        `
    }
    const query = gql`
        query getRewardOptionsForAssets() {
            ${slugs.map(getSubQueryForSingleAsset).join(' ')}
        }
    `
    return fetchApi(query)
}

export const fetchSelectedRewardOptionForStaking = async (
    wherePayload = {},
    orderPayload = {}
) => {
    const assetContent = gql`
        id
        slug
        name
        symbol
        logoUrl
        ${getMetricsQuery(['price'])}
    `

    const query = gql`
        query fetchSelectedRewardOptionForStaking(
            $where: RewardOptionsWhere!
        ) {
            rewardOptions(
                limit: 1
                offset: 0
                where: $where
                order: { providerIsVerified: desc, metricKey_desc: "staked_tokens" }
            ) {
                id
                type {
                    key
                }
                label
                rewardFormula
                stakeableInApp
                ${providerQuery}
                ${getMetricsQuery(['reward_rate'])}
                inputAssets(limit: 1) {
                    ${assetContent}
                }
                outputAssets (limit: 1) {
                    ${assetContent}
                }
                validators(limit: 1) {
                    id
                    address
                }
            }
        }
    `

    if (Object.keys(orderPayload).length === 0) {
        return await fetchApi(query, {
            where: wherePayload,
        })
    }

    return await fetchApi(query, {
        where: wherePayload,
        order: orderPayload,
    })
}

export const fetchRandomSelectedRewardOptionForStaking = async (
    wherePayload = {},
    orderPayload = {}
) => {
    const assetContent = gql`
        id
        slug
        name
        symbol
        logoUrl
        ${getMetricsQuery(['price'])}
    `

    const query = gql`
        query fetchSelectedRewardOptionForStaking(
            $where: RewardOptionsWhere!
        ) {
            rewardOptions(
                limit: 500
                offset: 0
                where: $where
                order: { providerIsVerified: desc, metricKey_desc: "staked_tokens" }
            ) {
                id
                type {
                    key
                }
                label
                rewardFormula
                stakeableInApp
                ${providerQuery}
                ${getMetricsQuery(['reward_rate'])}
                inputAssets(limit: 1) {
                    ${assetContent}
                }
                outputAssets (limit: 1) {
                    ${assetContent}
                }
                validators(limit: 1) {
                    id
                    address
                }
            }
        }
    `

    let result

    if (Object.keys(orderPayload).length === 0) {
        result = await fetchApi(query, {
            where: wherePayload,
        })
    } else {
        result = await fetchApi(query, {
            where: wherePayload,
            order: orderPayload,
        })
    }

    const verifiedOptions = result.rewardOptions.filter(
        option => option?.providers?.[0]?.isVerified
    )

    if (verifiedOptions.length === 0) return null

    const randomOption =
        verifiedOptions[Math.floor(Math.random() * verifiedOptions.length)]
    return { rewardOptions: [randomOption] }
}

export const fetchCosmosDataForPortfolio = async (slug, validators) => {
    const variables = {
        slug,
        validators,
    }
    const query = gql`
        query fetchCosmosDataForPortfolio(
            $slug: String!
            $validators: [String!]
        ) {
            rewardOptions(
                limit: 500
                where: {
                    isActive: Any
                    validators: { addresses: $validators }
                    inputAsset: { slugs: [$slug] }
                }
            ) {
                metrics(limit: 1, where: { metricKeys: ["reward_rate"] }) {
                    metricKey
                    defaultValue
                }
                providers(limit: 1) {
                    slug
                    name
                    logoUrl
                    isVerified
                }
                validators(limit: 500) {
                    address
                }
            }

            assets(limit: 1, where: { slugs: [$slug] }) {
                slug
                logoUrl
                symbol
                metrics(
                    limit: 500
                    where: { metricKeys: ["price", "unstaking_time"] }
                ) {
                    metricKey
                    defaultValue
                }
            }
        }
    `
    return fetchApi(query, variables)
}

export const fetchAdManagerTopPosition = async id => {
    try {
        const api = process.env.NEXT_PUBLIC_AD_MANAGER_URL + `/api/tp/${id}`

        const response = await fetch(api, {
            headers: {
                'X-API-VERSION': 3,
            },
        })

        const result = await response.json()

        const mongoTopPosition = result.filter(element =>
            element.hasOwnProperty('reward_option_mongo_id')
        )

        if (!mongoTopPosition?.[0]?.reward_option_mongo_id) {
            return null
        }

        const mongoIds = mongoTopPosition.map(
            element => element.reward_option_mongo_id
        )

        const query = gql`
            query ($id: String!) {
                rewardOptions(where: { ids: [$id] }, limit: 1) {
                    id
                    type {
                        key
                        label
                    }
                }
            }
        `

        const results = await Promise.all(
            mongoIds.map(async id => {
                const response = await fetchApi(query, { id })
                const trackingId = mongoTopPosition.filter(
                    element => element.reward_option_mongo_id === id
                )
                const responseWithTrackingId = {
                    ...response,
                    trackingId: trackingId?.[0].id,
                }
                return responseWithTrackingId
            })
        )

        return results
    } catch (err) {
        console.log(err)
    }
}

export const fetchDataAdPosition = async (
    wherePayload = {
        category: null,
        timeframe: DEFAULT_REWARD_OPTION_TIMEFRAME.key,
        profileType: TYPE_ASSET,
        rewardOptionId: null,
    }
) => {
    const query = gql`
        query getAdRewardOption(
          
        ) {
            rewardOptions(
                limit: 1
                where: {ids: ["${wherePayload.rewardOptionId}"], typeKeys: ["${wherePayload.category}"]}    
            ) {
                id
                label
                links
                stakeableInApp
                chains { key }
                type {
                    key
                }
                isActive
                chains {
                    key
                    label
                }
                ${providerQueryWithMetrics}
                inputAssets(limit: 1) {
                    slug
                }
                outputAssets(where: { isActive: True }, limit: 1) {
                    id
                    name
                    slug
                    symbol
                    logoUrl
                    description
                    isActive
                    isLaunched
                    metaWebApp
                    links
                    tags {tagKey, label}
                    metrics(limit:100){
                        defaultValue
                        metricKey
                        variation
                    }
                }
                ${getMetricsQuery([
                    'reward_rate',
                    'reward_frequency',
                    'minimum',
                    'commission',
                    'hosting_fee',
                    'staked_tokens',
                    'staking_share',
                    'self_staked_tokens',
                    'staking_wallets',
                    'performance',
                    'assets_under_management',
                    'is_not_delegation_node',
                ])}
                validators(
                    limit: 500, 
                    where: { 
                        isActive: True, 
                        status: { labels: ["active", "standby"] } 
                    }, 
                    order: { status: desc }
                ) {
                    id
                    address
                    isActive
                    status {
                        label
                    }
                    ${getMetricsQuery([
                        'reward_rate',
                        'reward_frequency',
                        'minimum',
                        'commission',
                        'hosting_fee',
                        'staked_tokens',
                        'staking_share',
                        'self_staked_tokens',
                        'staking_wallets',
                        'performance',
                        'node_id',
                        'is_delegation_node',
                    ])}
                }
            }
        }`

    return await fetchApi(query)
}

export const fetchLavaValidators = async ({
    limit = 500,
    metrics = [],
    where = {
        slugs: [],
    },
    offset = 0,
} = {}) => {
    const assetContent = gql`
        id
        slug
        name
        symbol
        logoUrl
        ${getMetricsQuery(['price'])}
        `

    const queryMetrics = ['reward_rate', ...metrics]

    const query = gql`
        query getLavaValidators() {
            rewardOptions(
                limit: ${limit}
                offset: ${offset}
                order: { 
                    validatorStatus: desc,
                    providerIsVerified: desc 
                }
                where: { 
                    stakeableInApp: true,
                    isActive: True,
                    inputAsset: { slugs: ["lava"] },
                    typeKeys: ["pos"],
                    metrics: {
                        metricKeys: ["reward_rate"],
                    },
                    providers: {
                        isActive: True,
                        ${(where?.slugs?.length ?? 0) > 0 ? `slugs: ${JSON.stringify(where?.slugs)}` : ''}
                    }
                }
            ) {
                id
                type {
                    key
                }
                label
                rewardFormula
                stakeableInApp
                ${providerQuery}
                validators(limit: 1) {
                    id
                    address
                }
                inputAssets(limit: 1) {
                    ${assetContent}
                }
                outputAssets (limit: 1) {
                    ${assetContent}
                }
                ${getMetricsQuery(queryMetrics)}
            }
        }
    `
    return await fetchApi(query)
}

export const fetchLavaProviders = async ({
    where = {
        slugs: [],
    },
} = {}) => {
    const assetContent = gql`
        id
        slug
        name
        symbol
        logoUrl
        ${getMetricsQuery(['price'])}
        `

    const query = gql`
        query getLavaProviders() {
            rewardOptions(
                limit: 500
                where: { 
                    stakeableInApp: true,
                    isActive: True,
                    inputAsset: { slugs: ["lava"] },
                    typeKeys: ["dual-staking"],
                    
                    providers: {
                        isActive: True,
                        ${(where?.slugs.length ?? 0) > 0 ? `slugs: ${JSON.stringify(where.slugs)}` : ''}
                    }
                }
            ) {
                id
                type {
                    key
                }
                label
                rewardFormula
                stakeableInApp
                ${providerQuery}
                validators(limit: 1) {
                    id
                    address
                }
                inputAssets(limit: 1) {
                    ${assetContent}
                }
                outputAssets (limit: 1) {
                    ${assetContent}
                }
                ${getMetricsQuery(['reward_rate'])}
                
            }
        }
    `

    return await fetchApi(query)
}

// TODO fix this during the refactor
export const fetchLiquidStakingOptionsForAtom = async () => {
    const query = gql`
        {
            rewardOptions(
                limit: 1
                offset: 0
                where: {
                    isActive: True
                    typeKeys: ["liquid-staking"]
                    stakeableInApp: true
                    inputAsset: { slugs: ["cosmos"] }
                    providers: { isActive: True, isClaimed: true }
                }
                order: {
                    providerIsVerified: desc
                    metricKey_desc: "staked_tokens"
                }
            ) {
                id

                providers(limit: 2) {
                    id
                    slug
                    name
                    country
                    logoUrl
                    isVerified
                    isActive
                    isClaimed
                    isReferral
                }

                metrics(
                    limit: 1
                    where: { metricKeys: ["reward_rate"] }
                    showAll: true
                ) {
                    id
                    metricKey
                    defaultValue
                }

                inputAssets(limit: 1) {
                    id
                    slug
                    name
                    symbol
                    logoUrl

                    metrics(
                        limit: 1
                        where: { metricKeys: ["price"] }
                        showAll: true
                    ) {
                        id
                        metricKey
                        defaultValue
                    }
                }
                outputAssets(limit: 1) {
                    id
                    slug
                    name
                    symbol
                    logoUrl

                    metrics(
                        limit: 1
                        where: { metricKeys: ["price"] }
                        showAll: true
                    ) {
                        id
                        metricKey
                        defaultValue
                    }
                }
            }
        }
    `

    return await fetchApi(query)
}

export const fetchAssetLinksBySlug = async (slug = '') => {
    const query = gql`
        {
            assets(where: { slugs: ["${slug}"] }, limit: 1) {
                id
                slug
                links
            }
        }
    `

    return await fetchApi(query)
}

export const fetchDropOperators = async ({ limit = 500, offset = 0 } = {}) => {
    const query = gql`
        query RewardOptions {
            rewardOptions(
                where: {
                    inputAsset: { slugs: ["cosmos"] }
                    typeKeys: ["operator"]
                    isActive: True
                }
                limit: ${limit}
                offset: ${offset}
            ) {
                id
                isActive
                label
                stakeableInApp
                providers(limit: ${limit}, where: { isClaimed: true }) {
                    id
                    name
                    slug
                    logoUrl
                    isVerified
                    metrics(where: { metricKeys: ["weight"] }, limit: ${limit}, order: { metricKey: desc }) {
                        metricKey
                        defaultValue
                        variation
                    }
                }
                metrics(limit: ${limit}) {
                    metricKey
                    defaultValue
                    variation
                }
            }
        }
    `
    return await fetchApi(query)
}

export const fetchBabylonStakedTokens = async () => {
    const result = await fetch('https://staking-api.babylonlabs.io/v1/stats')
    return result.json()
    // From SR global api
    // const query = gql`
    //     {
    //         metrics(
    //             limit: 2
    //             where: {
    //                 metricKeys: [
    //                     "babylon_staked_tokens"
    //                     "babylon_staking_wallets"
    //                 ]
    //             }
    //         ) {
    //             metricKey
    //             defaultValue
    //         }
    //     }
    // `
    // return await fetchApi(query)
}

export const fetchAssetChain = async (slugs = []) => {
    const variables = {
        slugs,
    }
    const query = gql`
        query fetchAssetLogoUrl($slugs: [String!]) {
            assets(limit: 1, where: { isActive: Any, slugs: $slugs }) {
                logoUrl
                name
                slug
            }
        }
    `

    return await fetchApi(query, variables)
}

export const fetchBabylonRewardOptions = async () => {
    const query = gql`
        {
            rewardOptions(
                limit: 500
                where: { typeKeys: ["babylon-staking"] }
            ) {
                id
                type {
                    key
                }
                stakeableInApp
                providers(limit: 1) {
                    slug
                    name
                    logoUrl
                    isVerified
                }
                validators(limit: 1) {
                    address
                }
                metrics(limit: 100) {
                    metricKey
                    defaultValue
                }
                inputAssets(limit: 1) {
                    id
                    slug
                    name
                    symbol
                    logoUrl
                    metrics(limit: 500) {
                        label
                        metricKey
                    }
                }
            }
        }
    `

    return await fetchApi(query)
}

export const fetchAssetTags = (slug = '') => {
    const query = gql`
        {
            assets(
                limit: 1
                where: { slugs: ["${slug}"] }
            ) {
                tags {
                    tagKey
                    label
                }

            }
        }
    `

    return fetchApi(query)
}

export const fetchLiquidStakingLombardRewardOptions = async () => {
    const query = gql`
        {
            rewardOptions(
                limit: 10
                where: { outputAsset: { slugs: ["lombard-staked-bitcoin"] } }
            ) {
                id
                label
                rewardInfo
                links
                type {
                    key
                }
                stakeableInApp

                providers(limit: 1) {
                    slug
                    name
                    logoUrl
                }
                metrics(limit: 100) {
                    metricKey
                    defaultValue
                    variation
                    tooltip
                }
                inputAssets(limit: 2) {
                    id
                    slug
                    name
                    symbol
                    logoUrl
                    metrics(limit: 500) {
                        label
                        metricKey
                    }
                }
            }
        }
    `
    return await fetchApi(query)
}

export const fetchRewardOptionDeal = async (id = []) => {
    const query = gql`
        {
            rewardOptions(where: { ids: ["${id}"] }, limit:1) {
                inputAssets (limit:1) {
                    slug
                    symbol
                }
                outputAssets(limit: 1) {
                    slug
                }
                type {
                    key
                    label
                }
                providers(limit:1){
                slug
           
            }
            }
        }
    `

    return fetchApi(query)
}
