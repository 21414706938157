import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import { useLockBodyScroll } from 'hooks/useLockBodyScroll'

import styles from './modal.module.scss'

export const Modal = ({
    className = '',
    overlayClassName = '',
    isOpen = false,
    onClose = () => {},
    isCloseable = true,
    children,
    header,
}) => {
    const overlayRef = useRef(null)
    const ref = useRef()
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        ref.current = document.querySelector('body')
        setIsMounted(true)
    }, [])

    useLockBodyScroll(isOpen)

    const closeModal = e => {
        e.preventDefault()
        e.stopPropagation()
        if (isCloseable) {
            onClose()
        }
    }

    const handleClickOutside = e => {
        if (e.target === overlayRef.current) {
            closeModal(e)
        }
    }

    return isMounted
        ? createPortal(
              <div
                  className={classNames(
                      styles.modalOverlay,
                      {
                          [styles.show]: isOpen,
                      },
                      overlayClassName
                  )}
                  onClick={handleClickOutside}
                  ref={overlayRef}
              >
                  <div
                      className={classNames(styles.modal, className, {
                          [styles.hideClose]: !isCloseable,
                      })}
                  >
                      {isCloseable && (
                          <div
                              className={styles.closeButtonWrap}
                              onClick={closeModal}
                          >
                              <span
                                  className={classNames(
                                      `icon icon-close !bg-contrast-6`,
                                      styles.closeButton
                                  )}
                              />
                          </div>
                      )}
                      <div className={styles.header}>
                          <div className={styles.title}>{header}</div>
                      </div>
                      <div className={styles.content}>{children}</div>
                  </div>
              </div>,
              ref.current
          )
        : null
}
